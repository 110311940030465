<template>
    <div style="display: inline-block">
        <b-button v-if="item.uid != 'novo'"  v-b-modal="item.uid" variant="info"><i class="fa fa-eye"></i> <i class="fa fa-edit"></i></b-button>
        <b-button v-if="item.uid == 'novo'" v-b-modal="item.uid" variant="info"><i class="fa fa-edit"></i> novo admin</b-button>
        <b-modal size="xl" :id="item.uid" :title="item.email">
            <b-overlay :show="is_load">
                <b-form @submit="saveCadastro">
                    <b-form-group label="Nome" class="required">
                        <b-form-input v-model="form.name" placeholder="" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="E-mail" class="required">
                        <b-form-input type="email" v-model="form.email" placeholder="" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Senha" class="">
                        <b-form-input type="password" v-model="form.password" placeholder=""></b-form-input>
                    </b-form-group>
                    <b-form-group label="Confirmação de senha" class="">
                        <b-form-input type="password" v-model="form.password_confirm" placeholder=""></b-form-input>
                    </b-form-group>

                    <b-button variant="outline-primary" size="lg" type="submit">Salvar cadastro</b-button>

                </b-form>
            </b-overlay>
            <template #modal-footer="{
                    // eslint-disable-next-line vue/no-unused-vars
                    ok, cancel, hide
                }">
                <p></p>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
    name: "modalCadastro",
    props: ['item'],
    emits: ['update'],
    data() {
        return {
            is_load: false,
            form: {
                name: '',
                email: '',
                password: '',
                password_confirm: '',
            }
        }
    },
    mounted() {
        this.form.name = this.item.displayName;
        this.form.email = this.item.email;
    },
    methods: {
        saveCadastro(evt) {
            evt.preventDefault();

            if (this.form.email === '') {
                Vue.swal({
                    title: 'E-mail inválido',
                    text: 'Por favor, digite um E-mail válido',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            if (this.form.password != this.form.password_confirm) {
                Vue.swal({
                    title: 'Senhas não são iguais',
                    text: 'Por favor, digite novamente as senhas, devem ser iguais',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            if (!this.item && (this.form.password === '' || this.form.password.length < 6)) {
                Vue.swal({
                    title: 'Senhas são obrigatórias',
                    text: 'Por favor, digite novamente as senhas, devem ser iguais',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                return;
            }


            this.is_load = true;
            this.form.uid = this.item.uid;
            console.log('this.form',this.form)
            axios.post('usuarios', this.form, {headers: {"Access-Control-Allow-Origin": true}}).then((response) => {
                console.log(response)
                Vue.swal({
                    title: 'Cadastro atualizado com sucesso',
                    type: 'success',
                    confirmButtonText: 'Ok'
                });
                this.is_load = false;
                this.form = {
                    name: '',
                    email: '',
                    password: '',
                    password_confirm: '',
                };
                this.$emit('update');
            }).catch((erro) => {
                console.log('erro erro', erro)
                this.is_load = false;
                Vue.swal({
                    title: 'Erro ao atualizar cadastro',
                    text: erro.response.data.erro,
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            })


        }
    }
}
</script>

<style scoped>

</style>